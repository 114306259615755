import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { SubCondition } from './SubCondition'
import { v4 } from 'uuid'

export const IFCondition = memo(
    ({ updateConditionById, entity, parameters }) => {
        const addResult = () => {
            updateConditionById({
                ...entity,
                rows: [
                    ...entity.rows,
                    {
                        id: v4(),
                        type: 'AND',
                        parameter: '',
                        condition: '',
                        value: '',
                    },
                ],
            })
        }
        const updateSubConditionById = (subCondition) => {
            updateConditionById({
                ...entity,
                rows: entity.rows.map((r) =>
                    r.id === subCondition.id ? subCondition : r,
                ),
            })
        }
        const removeSubConditionById = (id) => {
            updateConditionById({
                ...entity,
                rows: entity.rows.filter((r) => r.id !== id),
            })
        }

        return (
            <div className="formResult">
                <div className="resultTitle"> Condition Group</div>
                <div className="resultWrapper">
                    {(entity.rows || []).map((item, index) => {
                        return (
                            <SubCondition
                                updateSubConditionById={updateSubConditionById}
                                removeSubConditionById={removeSubConditionById}
                                subCondition={item}
                                parameters={parameters}
                                isFirst={index === 0}
                                key={item.id}
                            />
                        )
                    })}
                </div>

                <div className="addButton">
                    <ButtonWithIcon
                        onClick={addResult}
                        icon="/assets/images/icon+.png"
                        green={true}
                        value="Add Sub Condition"
                    />
                </div>
            </div>
        )
    },
)
