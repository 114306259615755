import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import {
    ruleConditionOptions,
    conditionTypes,
} from '../../../utils/selectOptions'

export const SubCondition = memo(
    ({
        parameters,
        subCondition,
        isFirst = false,
        updateSubConditionById,
        removeSubConditionById,
    }) => {
        const updateCondition = (key, value) => {
            updateSubConditionById({
                ...subCondition,
                [key]: value,
            })
        }
        const removeCondition = (id) => {
            removeSubConditionById(id)
        }
        return (
            <div
                className="formResult"
                style={{
                    border: '1px solid #blue',
                }}
            >
                <div className="formItem">
                    {!isFirst ? (
                        <SelectWithLabelInput
                            options={conditionTypes}
                            label="Type"
                            defaultValue={subCondition.type}
                            onChange={({ target }) => {
                                updateCondition('type', target.value)
                            }}
                        />
                    ) : (
                        <label className="firstIf">
                            <span>IF</span>
                        </label>
                    )}
                    <SelectWithLabelInput
                        options={parameters}
                        label="Parameter"
                        defaultValue={subCondition.parameter}
                        onChange={({ target }) => {
                            updateCondition('parameter', target.value)
                        }}
                    />
                    <SelectWithLabelInput
                        options={ruleConditionOptions}
                        label="Condition"
                        defaultValue={subCondition.condition}
                        onChange={({ target }) => {
                            updateCondition('condition', target.value)
                        }}
                    />

                    <TextInput
                        placeholder="Value"
                        label="Value"
                        value={subCondition.value}
                        onChange={({ target }) => {
                            updateCondition('value', target.value)
                        }}
                    />
                    <div
                        className="remove"
                        onClick={() => removeCondition(subCondition.id)}
                    >
                        -
                    </div>
                </div>
            </div>
        )
    },
)
