import React from "react";
import s from './SelectWithLabel.module.scss'

export const SelectWithLabelInput = ({ defaultValue='', onChange = () => {},onBlur =()=>{}, label="",placeholder,dark=true, marginTop,options=[], disabled=false }) => {
    return <label  className={`${dark? s.darkFormSelect : s.lightFormSelect} ${s.formSelect}`}>
        {label}     
        <select style={{marginTop:marginTop}} placeholder={placeholder} onChange={onChange} onBlur={onBlur} disabled={disabled?"disabled":""} value={defaultValue}>
            {options?.map((item, index) => {
                return <option key={index} value={item.value?item.value:item}>{item.name?item.name:item}</option>
            })}
        </select>
    </label>
}
