import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'

const CONDITION_OPTIONS = [
    { name: 'And', value: 'AND' },
    { name: 'Or', value: 'OR' },
]
export const IFConditionHeader = memo(
    ({ item, index, changeIfConditionType, removeIfCondition }) => {
        return index !== 0 ? (
            <>
                <SelectWithLabelInput
                    label="Group"
                    defaultValue={item.type}
                    onChange={({ target }) =>
                        changeIfConditionType(target.value, item.id)
                    }
                    options={CONDITION_OPTIONS}
                />
                <div
                    className="remove"
                    onClick={() => removeIfCondition(item.id)}
                >
                    -
                </div>
            </>
        ) : null
    },
)
