export const conditionOptions = [
    { name: 'Choose', value: null },
    { name: 'Is Equal', value: 'isEqual' },
    { name: 'More Than', value: 'moreThan' },
    { name: 'Less Than', value: 'lessThan' },
    { name: 'In Between', value: 'inBetween' },
    { name: 'In Range', value: 'inRange' },
]

export const ruleConditionOptions = [
    { name: '=', value: '=' },
    { name: '>=', value: '>=' },
    { name: '<=', value: '<=' },
    { name: '<>>', value: '!=' },
    { name: 'Includes', value: 'includes' },
    { name: 'Excludes', value: 'excludes' },
    { name: 'In Between', value: 'inBetween' },
]

export const conditionTypes = [
    { name: 'And', value: 'AND' },
    { name: 'Or', value: 'OR' },
]
