import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header } from './header'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { TextInput } from '../../Atoms/Inputs/Text'
import { IFCondition } from './IFCondition'
import { v4 } from 'uuid'
import { IFConditionHeader } from './IFConditionHeader'
import { ButtonWithIcon } from '../../Atoms/Buttons/ButtonWithIcon'
import { ResultRow } from './ResultRow'
import { useParams } from 'react-router-dom'
import { getParametersByDataModelId } from '../../../store/parameter/api'

const entityType = 'rule'
const entityURL = 'rules'
const TYPES = [
    { name: 'Rejection', value: 1 },
    { name: 'Limit Calculation', value: 2 },
]
const GROUPS = [
    { name: 'Creditor', value: 1 },
    { name: 'Guarantor', value: 2 },
]
const CONDITION_OPTIONS = [
    { name: 'And', value: 'AND' },
    { name: 'Or', value: 'OR' },
]
const conditions = []

export const CreateRule = () => {
    const [dataModels, setDataModels] = useState([])
    const [entity, setEntity] = useState({
        name: '',
        type: '1',
        groups: [],
        conditions: conditions || [],
        results: [],
        description: '',
    })
    const [parameters, setParameters] = useState([
        { name: 'Choose', value: null },
    ])
    const params = useParams()

    useEffect(() => {
        getParametersByDataModelId(
            { modelId: params.modelId },
            dataModels,
        ).then((_parameters) => {
            const list = _parameters.map((item) => {
                return { name: item.name, value: item._id }
            })
            setParameters([{ name: 'Choose', value: null }, ...list])
        })
    }, [])

    const onEntityChange = (key, body) => {
        setEntity({
            ...entity,
            [key]: body,
        })
    }
    const updateConditionById = (ConditionEntity) => {
        setEntity({
            ...entity,
            conditions: entity.conditions.map((condition) => {
                if (condition.id === ConditionEntity.id) {
                    return ConditionEntity
                }
                return condition
            }),
        })
    }

    const updateResultById = (ResultEntity) => {
        setEntity({
            ...entity,
            results: entity.results.map((result) => {
                if (result.id === ResultEntity.id) {
                    return ResultEntity
                }
                return result
            }),
        })
    }

    const addIfCondition = () => {
        setEntity({
            ...entity,
            conditions: [
                ...entity.conditions,
                {
                    id: v4(),
                    type: 'AND',
                    rows: [
                        {
                            id: v4(),
                            type: 'and',
                            parameter: '',
                            condition: '',
                            value: '',
                        },
                    ],
                },
            ],
        })
    }

    const addResultRow = () => {
        setEntity({
            ...entity,
            results: [
                ...entity.results,
                {
                    id: v4(),
                    parameter: '',
                    value: '',
                },
            ],
        })
    }

    const removeIfCondition = (id) => {
        setEntity({
            ...entity,
            conditions: entity.conditions.filter(
                (condition) => condition.id !== id,
            ),
        })
    }
    const removeResult = (id) => {
        setEntity({
            ...entity,
            results: entity.results.filter((result) => result.id !== id),
        })
    }

    const changeIfConditionType = (type, id) => {
        setEntity({
            ...entity,
            conditions: entity.conditions.map((condition) => {
                if (condition.id === id) {
                    condition.type = type
                }
                return condition
            }),
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="rightSide">
                <div className="codeWrapper">
                    <Header entity={entity} entityURL={entityURL} />
                    <div className="single-table-wrapper rule-content">
                        <div className="inputsWrapper">
                            <div className="row first-row">
                                <TextInput
                                    label="Name"
                                    value={entity?.name}
                                    onChange={({ target }) => {
                                        onEntityChange('name', target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <SelectWithLabelInput
                                    label="Type"
                                    defaultValue={entity?.type}
                                    onChange={({ target }) => {
                                        onEntityChange('type', target.value)
                                    }}
                                    options={TYPES}
                                />
                                <SelectWithLabelInput
                                    label="Group"
                                    defaultValue={entity?.groups}
                                    onChange={({ target }) => {
                                        onEntityChange('groups', target.value)
                                    }}
                                    options={GROUPS}
                                />
                            </div>
                            <div className="row second-row">
                                <div className="conditions-block">
                                    <div className="conditions-group">
                                        {entity.conditions.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="conditions"
                                                    >
                                                        <IFConditionHeader
                                                            changeIfConditionType={
                                                                changeIfConditionType
                                                            }
                                                            removeIfCondition={
                                                                removeIfCondition
                                                            }
                                                            item={item}
                                                            index={index}
                                                        />
                                                        <IFCondition
                                                            updateConditionById={
                                                                updateConditionById
                                                            }
                                                            entity={item}
                                                            parameters={
                                                                parameters
                                                            }
                                                        />
                                                    </div>
                                                )
                                            },
                                        )}
                                        <div className="conditionGroupButton">
                                            <ButtonWithIcon
                                                onClick={addIfCondition}
                                                icon="/assets/images/icon+.png"
                                                green={true}
                                                value="Add Condition Group"
                                            />
                                        </div>
                                    </div>
                                    <div className="results">
                                        <div className="resultTitle"> Then</div>
                                        {entity.results.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="conditions"
                                                >
                                                    <ResultRow
                                                        updateResultById={
                                                            updateResultById
                                                        }
                                                        removeResultById={
                                                            removeResult
                                                        }
                                                        result={item}
                                                        parameters={parameters}
                                                    />
                                                </div>
                                            )
                                        })}
                                        <ButtonWithIcon
                                            onClick={addResultRow}
                                            icon="/assets/images/icon+.png"
                                            green={true}
                                            value="Add Result"
                                        />
                                    </div>
                                </div>
                                <label>
                                    Description
                                    <textarea
                                        value={entity?.description}
                                        onChange={({ target }) => {
                                            onEntityChange(
                                                'description',
                                                target.value,
                                            )
                                        }}
                                        placeholder="Description"
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            marginTop: '7px',
                                        }}
                                        rows="5"
                                        cols="25"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
